import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { OldAlertService } from '../services/old-alert.service';
import { HttpCancelService } from '../services/http-cancel.service';
import { LoadingService } from '../services/loading.service';
import { AuthService } from '../services/auth.service';
import { ToastrService } from 'ngx-toastr';


@Injectable()
export class ResponseErrorInterceptor implements HttpInterceptor {

  constructor(
    private alerts: OldAlertService,
    private httpCancelService: HttpCancelService,
    private loadingService: LoadingService,
    private authService: AuthService,
    private toastr: ToastrService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.loadingService.show();
    this.loadingService.increment();
    return next.handle(request).pipe(
      catchError((errorResponse: any) => {
        switch (errorResponse.status) {
          case 0:
          case 500:
            var message = errorResponse.error.error || errorResponse.statusText;
            if(request.url.includes('/resource')) {
              this.toastr.error(message, "Documentale Error");
              return EMPTY;
            }
            this.alerts.showAlert("error", message);
            break;
          case 403:
            var message = errorResponse.error.error || errorResponse.statusText;
            this.alerts.showAlert("error", message);
            this.authService.logout();
            break;
        }
        this.httpCancelService.cancelPendingRequests();
        this.loadingService.reset();
        return throwError(errorResponse);
      }),
      finalize(() => {
        this.loadingService.decrement();
        if(this.loadingService.totalRequests == 0) {
          this.loadingService.hide()
        }
      })
    );
  }
}
